@import 'styles/includes.scss';

.Root {
    position: relative;
}

.NativePlayerWrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    video {
        width: 100%;
        height: auto;
    }
}

.ExternalPlayerWrapper {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.Player {
    position: absolute;
    top: 0;
    left: 0;
}

.FallbackImage {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ConsentOverlay {
    padding: 1.5rem;
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: $color-dark-violet;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-bottom: 1.6rem;
        text-align: center;
        color: $color-white;
    }
}

.ConsentButton {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px !important;
    text-decoration: none;
    font-family: $base-font !important;

    border-radius: 4px !important;
    background-color: $color-indigo !important;
    border: 1px dotted transparent !important;

    @include navigation-titles;
    color: $color-white;

    @include media(sl) {
        display: inline-flex;
        height: 56px;
        padding: 0 24px;
    }

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        border: 1px dotted #fff;
        text-decoration: underline;
    }
}
